(function($){
'use strict';

  //Slideshow
  $("#slideshow > div:gt(0)").hide();

  setInterval(function() {
    $('#slideshow > div:first')
      .fadeOut(2000)
      .next()
      .fadeIn(2500)
      .end()
      .appendTo('#slideshow');
  },  8000);

})(jQuery);
